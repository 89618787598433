<template>
  <transition
    name="dialog-top-transition"
    appear
    mode="out-in"
  >
    <v-banner
      :model-value="modelValue"
      v-bind="safeAttrs"
    >
      <template #text>
        <slot name="default">
          <!--injected message goes here-->
        </slot>
      </template>

      <template #actions>
        <slot name="actions">
          <!--injected actions goes here-->
        </slot>
      </template>
    </v-banner>
  </transition>
</template>

<script>
import { defaults } from 'lodash-es';

export default defineComponent({
  name: 'MtfBanner',
  inheritAttrs: true,
  customOptions: {},
  props: {
    modelValue: Boolean
  },
  computed: {
    safeAttrs() {
      return defaults(
        { ...this.$attrs },
        {
          transition: 'dialog-bottom-transition',
          lines: 'two',
          density: 'comfortable',
          rounded: '0',
          stacked: true
        }
      );
    }
  }
});
</script>
